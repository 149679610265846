import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Stack = makeShortcode("Stack");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <Stack alignSelf="stretch" width="70rem" mdxType="Stack">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/841232816cebb7d3eb4fb86a2605b119/0f98f/kronos_title_card.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.333333333333336%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAehqkpzjFKP/xAAcEAABAwUAAAAAAAAAAAAAAAABAgMRAAQSITL/2gAIAQEAAQUCbhIKhiTJa5ujuv/EABcRAAMBAAAAAAAAAAAAAAAAAAABEQL/2gAIAQMBAT8BTuaQ/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRABAAIDAAAAAAAAAAAAAAAAAQIQABEi/9oACAEBAAY/AuYuLI0WFf/EABsQAAIDAAMAAAAAAAAAAAAAAAERACFREDFx/9oACAEBAAE/IQtIbsaADYVk7vYS9Sa4/9oADAMBAAIAAwAAABAYP//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxDMgAwv/8QAFREBAQAAAAAAAAAAAAAAAAAAEEH/2gAIAQIBAT8Qp//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQXEQ/9oACAEBAAE/EEYgBqW47YAxm158jCyHRfBGpLhmaW29L+f/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Rebel Kronos",
                "title": "Rebel Kronos",
                "src": "/static/841232816cebb7d3eb4fb86a2605b119/e5166/kronos_title_card.jpg",
                "srcSet": ["/static/841232816cebb7d3eb4fb86a2605b119/f93b5/kronos_title_card.jpg 300w", "/static/841232816cebb7d3eb4fb86a2605b119/b4294/kronos_title_card.jpg 600w", "/static/841232816cebb7d3eb4fb86a2605b119/e5166/kronos_title_card.jpg 1200w", "/static/841232816cebb7d3eb4fb86a2605b119/d9c39/kronos_title_card.jpg 1800w", "/static/841232816cebb7d3eb4fb86a2605b119/0f98f/kronos_title_card.jpg 1920w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Stack>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      